
import { IStep } from '@/interfaces/step';
import Base from '@/mixins/Base.vue';
import moment from 'moment';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    computed: {
        startDate() {
            if (this.items.data.length) {
                const startDate = this.items.data.reduce((prev: {
                    starts_at: number;
                }, curr: {
                    starts_at: number;
                }) => (prev.starts_at < curr.starts_at ? prev : curr)).starts_at;

                return moment(startDate)
                    .startOf('month');
            }
            return null;
        },
        endDate() {
            if (this.items.data.length) {
                const endDate = this.items.data.reduce((prev: {
                    ends_at: number;
                }, curr: {
                    ends_at: number;
                }) => (prev.ends_at > curr.ends_at ? prev : curr)).ends_at;

                return moment(endDate)
                    .endOf('month');
            }
            return null;
        },
        totalNumberOfMonths() {
            return moment(this.endDate).diff(this.startDate, 'months');
        },
        totalNumberOfDays() {
            return moment(this.endDate).diff(this.startDate, 'days');
        },
    },
    data(): any {
        return {
            isAllStepsOpened: false,
            moment,
            columnWidth: 300,
            zoom: 1,
            project: undefined,
            items: {
                data: [] as Array<IStep>,
                meta: {
                    total: 0,
                },
            },
        };
    },
    mounted(): void {
        this.getItems();
    },
    methods: {
        getItems(page: number = 1): void {
            this.get(`projects/${this.$route.params.project_id}`, {
                page,
            })
                .then(({ data }: any) => {
                    this.project = data.data;

                    this.get(`projects/${this.$route.params.project_id}/steps`)
                        .then(({ data }: any) => {
                            this.items.data = data.data;
                            this.items.meta = data.meta;

                            this.$nextTick(() => {
                                const columnWidth = this.$refs.timeline.clientWidth / (this.totalNumberOfMonths + 1);
                                this.columnWidth = columnWidth > 300 ? columnWidth : 300;
                            });
                        });
                });
        },

        calcStepWidth(step: any) {
            const startDate = moment(step.starts_at);
            const endDate = moment(step.ends_at);
            const numDays = Math.abs(endDate.diff(startDate, 'days')) + 1;

            return (100 / this.totalNumberOfDays) * numDays;
        },
        calcStepOffsetLeft(step: any) {
            const startDate = moment(step.starts_at);
            const numDays = Math.abs(moment(this.startDate)
                .diff(startDate, 'days'));
            return (100 / this.totalNumberOfDays) * numDays;
        },
    },
});
